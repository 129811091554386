import React from "react";

function Skills() {
  return (
    <section
      className="skills-img section-padding position-re"
      data-scroll-index="2"
    >
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5">
            <div className="img md-mb50">
              <img
                src="/dark/assets/imgs/background/BrentServices.jpg"
                alt=""
                className="radius-30"
              />
              <div className="curv-title main-bg">
                <h6 className="sub-title">Brent Schoenmakers</h6>
                <div className="shap-left-bottom">
                  <svg
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-11 h-11"
                  >
                    <path
                      d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                      fill="#1a1a1a"
                    ></path>
                  </svg>
                </div>
                <div className="shap-right-bottom">
                  <svg
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-11 h-11"
                  >
                    <path
                      d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                      fill="#1a1a1a"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 valign">
            <div className="content full-width">
              <div className="sec-head mb-30">
                <h6 className="sub-title mb-15 main-color">My Skills</h6>
                <h2 className="text-u">
                  My skills and experience
                </h2>
              </div>
              <div className="row justify-content-end">
                <div className="col-lg-11">
                  <div className="text">
                    <p>
                      Delivering results-driven web solutions with a focus on
                      user experience and performance. My 5+ years of front-end
                      development and 2+ years of full-stack experience make me
                      a valuable asset to any team.
                    </p>
                  </div>
                  <div className="mt-50">
                    <div className="skills-box">
                      <div className="skill-item mb-40">
                        <h5 className="sub-title mb-15">UI / UX Design</h5>
                        <div className="skill-progress">
                          <div className="progres" data-value="95%"></div>
                        </div>
                      </div>
                      <div className="skill-item mb-40">
                        <h5 className="sub-title mb-15">HTML, CSS, JS</h5>
                        <div className="skill-progress">
                          <div className="progres" data-value="95%"></div>
                        </div>
                      </div>
                      <div className="skill-item mb-40">
                        <h5 className="sub-title mb-15">TypeScript</h5>
                        <div className="skill-progress">
                          <div className="progres" data-value="90%"></div>
                        </div>
                      </div>
                      <div className="skill-item mb-40">
                        <h5 className="sub-title mb-15">Angular</h5>
                        <div className="skill-progress">
                          <div className="progres" data-value="80%"></div>
                        </div>
                      </div>
                      <div className="skill-item mb-40">
                        <h5 className="sub-title mb-15">React & NextJS</h5>
                        <div className="skill-progress">
                          <div className="progres" data-value="75%"></div>
                        </div>
                      </div>
                      <div className="skill-item mb-40">
                        <h5 className="sub-title mb-15">NestJS</h5>
                        <div className="skill-progress">
                          <div className="progres" data-value="75%"></div>
                        </div>
                      </div>
                      <div className="skill-item mb-40">
                        <h5 className="sub-title mb-15">DotNet</h5>
                        <div className="skill-progress">
                          <div className="progres" data-value="70%"></div>
                        </div>
                      </div>
                      <div className="skill-item mb-40">
                        <h5 className="sub-title mb-15">VueJS</h5>
                        <div className="skill-progress">
                          <div className="progres" data-value="65%"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="line-overlay">
        <svg
          viewBox="0 0 1728 1101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-43 773.821C160.86 662.526 451.312 637.01 610.111 733.104C768.91 829.197 932.595 1062.9 602.782 1098.75C272.969 1134.6 676.888 25.4306 1852 1"
            style={{ strokeDasharray: 3246.53 }}
          ></path>
        </svg>
      </div>
    </section>
  );
}

export default Skills;
