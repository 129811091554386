import React from "react";

function Process() {
  return (
    <section className="process section-padding" data-scroll-index="4">
      <div className="container">
        <div className="sec-head mb-80">
          <h6 className="sub-title main-color mb-25">The Process</h6>
          <div className="bord pt-25 bord-thin-top d-flex align-items-center">
            <h2 className="fw-600">
              Web App <span className="fw-200">Development</span>
            </h2>
          </div>
        </div>
        <div className="row md-marg">
          <div className="col-lg-3 col-md-6 item md-mb50">
            <div className="icon-img-60 mb-30">
              <img src="/dark/assets/imgs/serv-icons/3.png" alt="" />
            </div>
            <h5 className="mb-15">Prototype and design</h5>
            <p>
              First of all I will create a sketch of the website on paper and
              then prototype and design it using Figma.
            </p>
          </div>
          <div className="col-lg-3 col-md-6 item md-mb50">
            <div className="icon-img-60 mb-30">
              <img src="/dark/assets/imgs/serv-icons/4.png" alt="" />
            </div>
            <h5 className="mb-15">Skeleton and design</h5>
            <p>
              After I have built the starting design / prototype, I will start
              to build the skeleton of the website using the specified tools /
              frameworks.
            </p>
          </div>
          <div className="col-lg-3 col-md-6 item sm-mb50">
            <div className="icon-img-60 mb-30">
              <img src="/dark/assets/imgs/serv-icons/5.png" alt="" />
            </div>
            <h5 className="mb-15">Interactivity</h5>
            <p>
              After the skeleton is built, I will start to add interactivity to
              communicate with the application.
            </p>
          </div>
          <div className="col-lg-3 col-md-6 item">
            <div className="icon-img-60 mb-30">
              <img src="/dark/assets/imgs/serv-icons/6.png" alt="" />
            </div>
            <h5 className="mb-15">Final touches</h5>
            <p>
              After everything is done, I will add the final touches to the web
              app and make sure everything is working correctly.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Process;
