import { Helmet } from "react-helmet";
import Lines from "./components/common/Lines";
import ProgressScroll from "./components/common/ProgressScroll";
import Cursor from "./components/common/cusor";
import LoadingScreen from "./components/common/loader";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Portfolio from "./components/Portfolio";
import Price from "./components/Price";
import Process from "./components/Process";
import Services from "./components/Services";
import Skills from "./components/Skills";
import Marquee from "./components/Marquee";
import Contact from "./components/Contact";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>BrentWeb</title>
        <link rel="icon" href="/dark/imgs/favicon.ico" />
        <link rel="shortcut icon" href="/dark/imgs/favicon.ico" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/plugins.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/style.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/satoshi.css"
        />

        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght..200;300;400;500;600;700&display=swap"
        />
        <script src="/dark/assets/js/scripts.js"></script>
        <script src="/dark/assets/js/smoother-script.js"></script>
      </Helmet>
      <body>
        <LoadingScreen />
        <Cursor />
        <ProgressScroll />
        <Lines />
        <div id="smooth-wrapper">
          <Navbar />
          <div id="smooth-content">
            <main className="main-bg pt-80">
              <Header />
              <Marquee />
              {/* <Brands /> */}
              <Services />
              <Skills />
              <Portfolio />
              <Process />
              {/* <Testimonials /> */}
              <Price />
              {/* <Blog /> */}
              <Contact />
            </main>
            <Footer />
          </div>
        </div>
      </body>
    </>
  );
}
