"use client";
import React, { useEffect, useRef, useState } from "react";

function Contact() {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });

    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);

  return (
    <section
      className="contact-crev section-padding bord-thin-bottom bord-thin-top"
      data-scroll-index="6"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="sec-head md-mb80">
              <h6 className="sub-title main-color mb-15 wow fadeInUp">
                Get In Touch
              </h6>
              <h2
                className={`text-u ls1 d-rotate ${isVisible ? "animated" : ""}`}
                ref={domRef}
              >
                <span className="rotate-text">
                  Let's make the website of
                  <span className="fw-200">the future!</span>
                </span>
              </h2>
              <p className="mt-10">
                Contact me if you want to make a website that will make your
                business stand out from the rest.
              </p>
              <div className="mail fz-30 fw-600 mt-80 underline main-color">
                <a href="tel:+">+32 474 06 09 87</a>
              </div>
              <div className="phone fz-30 fw-600 mt-20 underline main-color">
                <a href="mailto:brent@brentweb.eu">Brent@brentweb.eu</a>
              </div>
              <ul className="rest social-text d-flex mt-40">
                <li className="mr-30">
                  <a
                    href="https://www.linkedin.com/in/brent-schoenmakers-3793a8262/"
                    className="hover-this"
                  >
                    <span className="hover-anim">LinkedIn</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/brentweb.eu/"
                    className="hover-this"
                  >
                    <span className="hover-anim">Instagram</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1 valign">
            <div className="full-width">
              <form
                id="contact-form"
                method="POST"
                noValidate={false}
              >
                <div className="messages"></div>

                <div className="controls row">
                  <div className="col-lg-6">
                    <div className="form-group mb-30">
                      <input
                        id="form_name"
                        type="text"
                        name="name"
                        placeholder="Name"
                        required="required"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group mb-30">
                      <input
                        id="form_email"
                        type="email"
                        name="email"
                        placeholder="Email"
                        required="required"
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group mb-30">
                      <input
                        id="form_subject"
                        type="text"
                        name="subject"
                        placeholder="Subject"
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        id="form_message"
                        name="message"
                        placeholder="Message"
                        rows="4"
                        required="required"
                      ></textarea>
                    </div>
                    <div className="mt-30">
                      <button
                        type="submit"
                        className="butn butn-full butn-bord radius-30"
                      >
                        <span className="text">Let&#39;s Talk</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
