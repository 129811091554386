import React from "react";

function Footer() {
  return (
    <footer
      className="clean-footer crev bg-img"
      data-background="/dark/asstes/imgs/header/bg1.jpg"
      data-overlay-dark="10"
    >
      <div className="container pb-40 section-padding ontop">
        <div className="d-flex align-items-center pt-30 pb-30 mt-80 bord-thin-top">
          <div>
            <ul className="rest d-flex align-items-center">
              <li className="hover-this cursor-pointer ml-30">
                <a
                  href="https://www.linkedin.com/in/brent-schoenmakers-3793a8262/"
                  className="hover-anim"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li className="hover-this cursor-pointer ml-30">
                <a
                  href="https://www.instagram.com/brentweb.eu/"
                  className="hover-anim"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="ml-auto">
            <p className="fz-14">© 2024 BrentWeb. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
